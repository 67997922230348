import { Alert, Typography } from "@mui/material";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./index.css";

export const throwErrorToast = (message: string) => {
    toast.error(
        <>
            <Alert sx={{ width: 280 }} variant="filled" severity="error">
                <Typography
                    component="span"
                    fontSize={14}
                    lineHeight="20px"
                    letterSpacing="0.15px"
                >
                    {message}
                </Typography>
            </Alert>
        </>,
        {
            toastId: message
        }
    );
};
export const throwWarningToast = (message: string) => {
    toast.warning(
        <>
            <Alert sx={{ width: 280 }} variant="filled" severity="warning">
                <Typography
                    component="span"
                    fontSize={14}
                    lineHeight="20px"
                    letterSpacing="0.15px"
                >
                    {message}
                </Typography>
            </Alert>
        </>,
        {
            toastId: message
        }
    );
};
export const throwSuccessToast = (message: string, id?: string) => {
    toast.success(
        <>
            <Alert sx={{ width: 280 }} variant="filled" severity="success">
                <Typography
                    component="span"
                    fontSize={14}
                    lineHeight="20px"
                    letterSpacing="0.15px"
                >
                    {message}
                </Typography>
            </Alert>
        </>,
        {
            toastId: id || message
        }
    );
};

export const Toaster = () => (
    <ToastContainer
        position="top-right"
        hideProgressBar={true}
        transition={Slide}
        closeButton={false}
        pauseOnFocusLoss={false}
        icon={false}
    />
);
