import { Box, CircularProgress } from "@mui/material";

export const OverlayLoader = () => (
    <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        alignItems="center"
        justifyContent="center"
    >
        <CircularProgress />
    </Box>
);
