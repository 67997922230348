import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { CssBaseline } from "@mui/material";

import { App } from "./App";
import "./features/utils/i18n";
import { OverlayLoader } from "./ui/atoms/OveralyLoader";
import { QueryClientProvider, QueryClient } from "react-query";
import { throwErrorToast, Toaster } from "./ui/organisms/Toaster";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { translator } from "./features/utils/i18n";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: async () =>
        throwErrorToast(await translator.then((t) => t("common.smthWrong"))),
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      retry: false,
    },
    mutations: {
      onError: async () =>
        throwErrorToast(await translator.then((t) => t("common.smthWrong"))),
    },
  },
});

export const history = createBrowserHistory();
ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <CssBaseline />
      <Suspense fallback={<OverlayLoader />}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <App />
            <Toaster />
          </QueryClientProvider>
        </ThemeProvider>
      </Suspense>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
