import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import "intl-pluralrules";

import dayjs from "dayjs";
import "dayjs/locale/es";

export const translator = i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        supportedLngs: ["en", "es"],
        interpolation: {
            escapeValue: false
        }
    });
i18n.on("languageChanged", (lng) => {
    document.documentElement.setAttribute("lang", lng);
    dayjs.locale(lng);
});
