import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import { User } from "../../types";
import { MeAPI } from "../../utils/api/requests/me-requests";

export class AppStore {
    sessionId: string | null = null;
    user: User | null = null;
    isNavHidden = false;

    constructor() {
        makeAutoObservable(this);
        this.load();
    }

    get role() {
        return this.user?.role;
    }

    get isMerchant() {
        return (["merchant", "merchantEmployee"] as User["role"][]).includes(
            this.user?.role ?? "user"
        );
    }

    load = () => {
        this.sessionId = window.localStorage.getItem("sessionId");
        this.sessionId &&
            MeAPI.getProfile().then((user) => {
                this.setUser(user);
            });
    };

    setSessionId = (id: string) => {
        this.sessionId = id;
        window.localStorage.setItem("sessionId", id);
        this.load();
    };
    setUser = (user: User) => {
        this.user = user;
    };

    logout = () => {
        this.sessionId = null;
        this.user = null;
        window.localStorage.removeItem("sessionId");
    };
    setNavStatus = (flag: boolean) => {
        this.isNavHidden = flag;
    };
}

export const AppStoreInstance = new AppStore();
export const appStore = createContext(AppStoreInstance);
