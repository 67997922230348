import { get, put } from "../axiosConfig";
import { ChangePassForm } from "../../../user/organisms/ChangePasswordModal";
import { User } from "../../../types";
import { getUserFields } from "../fields/me";
import i18next from "i18next";
import { translator } from "../../i18n";

export const MeAPI = {
    async getProfile(): Promise<User> {
        // wait for translation will be loaded
        await translator;

        const params = {
            _fields: getUserFields(i18next.language)
        };
        const { data } = await get(`/api/v1/me`, { params });
        return data.result;
    },
    async updateProfile(user: Partial<User>): Promise<User> {
        const params = {
            _fields: getUserFields(i18next.language)
        };

        const { data } = await put("/api/v1/user/settings", user, { params });

        return data.result;
    },

    async changePassword(formData: ChangePassForm): Promise<void> {
        const { data } = await put(`/api/v1/user/password`, {
            ...formData
        });
        return data.result;
    }
};
