import { reduceList } from "../../reduceList";

const getFields = (locale: string) => [
  "role",
  { photo: ["url"] },
  "isBlocked",
  "lastName",
  "firstName",
  "login",
  "phone",
  "email",
  "accessGroups",
  "storeId",
  "tfaEnabled",
  {
    store: [
      { name: [locale] },
      { logo: ["url"] },
      { commissions: ["value", "name"] },
    ],
  },
];
export const getUserFields = (locale: string) => reduceList(getFields(locale));

export const userSessionID = reduceList([
  "sessionId",
  { user: ["role", "tfaEnabled", "tfaRequired"] },
]);
