import { createTheme, ThemeOptions } from "@mui/material/styles";
import type {} from "@mui/lab/themeAugmentation";

declare module "@mui/material/Chip" {
    interface ChipPropsVariantOverrides {
        status: true;
    }
}
declare module "@mui/material/Badge" {
    interface BadgePropsColorOverrides
        extends Record<keyof StatusPallete, true> {}
}
export interface StatusPallete {
    "awaiting-packaging": React.CSSProperties["color"];
    "ready-for-delivery": React.CSSProperties["color"];
    "courier-appointed": React.CSSProperties["color"];
    delivering: React.CSSProperties["color"];
    delivered: React.CSSProperties["color"];
    "partially-canceled": React.CSSProperties["color"];
    active: React.CSSProperties["color"];
    canceled: React.CSSProperties["color"];
    "awating-moderation": React.CSSProperties["color"];
    pending: React.CSSProperties["color"];
    "awaiting-cancelation": React.CSSProperties["color"];
}
export type Statuses = keyof StatusPallete;

declare module "@mui/material/styles/createTheme" {
    interface Theme {
        status: StatusPallete;
    }
    interface ThemeOptions {
        status: StatusPallete;
    }
}
export const NAVBAR_HEIGHT = 80;

const ColorSchema: StatusPallete = {
    "awaiting-packaging": "#FF9800",
    "ready-for-delivery": "#F88078",
    "courier-appointed": "#7986CB",
    delivering: "#7BC67E",
    delivered: "#4CAF50",
    "partially-canceled": "#FF4081",
    "awaiting-cancelation": "#FF4081",
    active: "#4CAF50",
    canceled: "#F44336",
    "awating-moderation": "#FF9800",
    pending: "#F88078"
};
export const palette = {
    background: {
        default: "#FBFBFD"
    },
    primary: {
        main: "#3F51B5",
        light: "#7986CB",
        dark: "#303F9F",
        contrastText: "#FFFFFF"
    },
    secondary: {
        main: "#F50057"
    },
    text: {
        primary: "#212121",
        secondary: "#666666",
        disabled: "#9E9E9E"
    },
    action: {
        active: "rgba(33, 35, 51, 0.5)",
        hover: "rgba(0, 0, 0, 0.04)",
        disabled: "rgba(0, 0, 0, 0.26)",
        disabledBackground: "rgba(0, 0, 0, 0.12)"
    },
    divider: "rgba(0, 0, 0, 0.12)",
    error: {
        main: "#F50057"
    },
    warning: {
        main: "#FF9800",
        contrastText: "#FFFFFF"
    },

    ...Object.fromEntries(
        Object.entries(ColorSchema).map((entry) => [
            entry[0],
            {
                main: entry[1],
                contrastText: "#FFFFFF"
            }
        ])
    )
};

export const theme = createTheme({
    status: ColorSchema,
    palette,
    components: {
        MuiBadge: {
            variants: Object.entries(ColorSchema).map((entry) => ({
                props: { color: entry[0] },
                style: {}
            }))
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.07) !important"
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    justifyContent: "space-between",
                    height: NAVBAR_HEIGHT + "px"
                }
            }
        },

        MuiFormControl: {
            styleOverrides: {
                root: {
                    "&.viewMode .MuiInputBase-input": {
                        paddingLeft: 0,
                        color: palette.text.primary + " !important",
                        WebkitTextFillColor:
                            palette.text.primary + " !important"
                    },
                    "&.viewMode .Mui-disabled.MuiSelect-iconOutlined": {
                        display: "none"
                    },
                    "&.viewMode .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent !important"
                    },
                    "&.viewMode .MuiInputLabel-formControl": {
                        transform: "translate(0px, -9px) scale(0.75) !important"
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: "8px !important",
                    "&.Mui-disabled input:disabled": {
                        color: "#A6A6A6",
                        WebkitTextFillColor: "#A6A6A6"
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                asterisk: {
                    color: "#F44336",
                    "&$error": {
                        color: "#F44336"
                    }
                }
            }
        },
        MuiButtonGroup: {
            styleOverrides: {
                root: {
                    borderRadius: "8px"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "8px"
                },
                contained: {
                    ":hover": {
                        backgroundColor: "#3F51B5"
                    }
                },
                outlined: {
                    ":hover": {
                        backgroundColor: "rgba(63, 81, 181, 0.08)"
                    }
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: "16px 24px 24px 24px"
                }
            }
        },
        MuiChip: {
            variants: [
                {
                    props: { variant: "status" },
                    style: {
                        [`&.MuiChip-root.MuiChip-label`]: {
                            padding: "3px 10px"
                        },
                        fontSize: "13px",
                        lineHeight: "18px",
                        height: "24px"
                    }
                }
            ]
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    minHeight: "100%",
                    border: 0,
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
                        {
                            outline: "none"
                        },
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-root .MuiDataGrid-cell":
                        {
                            padding: "0px 8px 0px 16px"
                        },
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer ":
                        {
                            padding: 0
                        },

                    "&.MuiDataGrid-root .pointer": {
                        cursor: "pointer"
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none"
                    }
                },
                footerContainer: {
                    justifyContent: "flex-start !important",
                    padding: 0,
                    minHeight: "66px !important"
                },

                cell: {
                    padding: "16px !important",
                    lineHeight: "20px !important"
                }
            }
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    li: {
                        marginRight: "6px",
                        "&:last-of-type": {
                            marginRight: 0
                        }
                    }
                }
            }
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    margin: 0
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: "#fff",
                    color: palette.text.primary,
                    filter: "drop-shadow(0px 6px 54px rgba(0, 0, 0, 0.07))",
                    padding: "16px",
                    maxWidth: "240px",
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: "normal",
                    borderRadius: "4px",
                    whiteSpace: "pre-line"
                },
                tooltipPlacementTop: {
                    margin: "6px 0 !important"
                },
                tooltipPlacementBottom: {
                    margin: "6px 0 !important"
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    boxShadow: "none !important"
                }
            }
        }
    }
} as ThemeOptions);
